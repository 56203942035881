<template>
    <div id="willOverview">
        <div class="vx-row">
            <div
                class="vx-col w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 mb-base"
            >
                <vx-card
                    v-if="data"
                    title="People per Will"
                    :subtitle="subTitle"
                    class="no-over"
                >
                    <div slot="no-body">
                        <div
                            class="p-6"
                            :class="{
                                'flex justify-between flex-row-reverse items-center': false,
                                'text-center': true,
                                'pb-0': true,
                            }"
                        >
                            <div class="">
                                <div class="mb-3">
                                    <h2 class="mb-1 font-bold">
                                        {{
                                            data.people_in_will
                                                ? data.people_in_will.toFixed(1)
                                                : '0'
                                        }}
                                    </h2>
                                    <span>People</span>
                                </div>
                                <div class="mb-3">
                                    <h2 class="mb-1 font-bold">
                                        {{
                                            data.share_people_average
                                                ? data.share_people_average.toFixed(
                                                      1
                                                  )
                                                : '0'
                                        }}
                                    </h2>
                                    <span>Share people</span>
                                </div>
                            </div>
                            <div>
                                <div class="mb-3">
                                    <h2 class="mb-1 font-bold">
                                        {{
                                            data.average_with_phone
                                                ? data.average_with_phone.toFixed(
                                                      1
                                                  )
                                                : '0'
                                        }}
                                    </h2>
                                    <span>Share people per will w/phone</span>
                                </div>
                                <div class="mb-3">
                                    <h2 class="mb-1 font-bold">
                                        {{
                                            data.average_with_email
                                                ? data.average_with_email.toFixed(
                                                      1
                                                  )
                                                : '0'
                                        }}
                                    </h2>
                                    <span>Share people per will w/email</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <vs-divider></vs-divider>

                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="flex justify-around items-end">
                                <div class="mr-3">
                                    <label for="">Custom Date Range</label>
                                    <DatePicker
                                        mode="range"
                                        v-model="range"
                                    ></DatePicker>
                                </div>

                                <div class="cursor-pointer">
                                    <vs-button
                                        radius
                                        type="flat"
                                        icon-pack="feather"
                                        icon="icon-refresh-ccw"
                                        @click="reportDataFetch"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import { http, httpGoCardless } from '@/services'
import { textFormat } from '@/mixins/TextFormat'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'ReportShareView',
    mixins: [textFormat],
    components: {
        DatePicker,
    },
    props: [],
    created() {
        this.reportDataFetch()
    },
    beforeRouteUpdate(to, from, next) {
        this.reportDataFetch()
        next()
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    beforeRouteLeave(to, from, next) {
        next()
    },
    computed: {
        subTitle() {
            if (this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 days'
        },
    },
    data() {
        return {
            data: null,
            backPath: null,
            range: {
                start: null,
                end: null,
            },
        }
    },
    methods: {
        reportDataFetch(id) {
            this.$vs.loading()

            http.get('report_share', {
                params: {
                    start: this.range.start,
                    end: this.range.end,
                },
            })
                .then((response) => {
                    this.data = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
    },
}
</script>

<style scoped>
.no-over {
    overflow: visible;
}
</style>
